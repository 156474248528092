import { MsalGuardConfiguration } from "@azure/msal-angular";
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, PublicClientApplication } from "@azure/msal-browser";
import { environment } from "../environments/environment";

export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: environment.msalAuthConfig,
        cache: {
            cacheLocation: BrowserCacheLocation.LocalStorage
        },
        system: {
            iframeHashTimeout: 10000,
            allowRedirectInIframe: true,
            allowNativeBroker: false, // Disables WAM Broker
        },
    });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: {
            scopes: [...environment.msalScope]
        },
        loginFailedRoute: '/login-failed' // TODO: create a login-failed component or atleast handle the login failure
    };
}