<div class="d-inline-block btn-outer-wrapper" [ngClass]="btnClass" (click)="navLink && openUrl(navLink)">
    <div class="btn-group btn-wrapper bg-white not-selectable">
        <button type="submit" class="bg-white text-nowrap" [class.hvr-icon-bob]="hoverIconBob"
            (mouseover)="onMouseOver()" (mouseleave)="onMouseLeave()" (click)="double && onPressFirstBtn()">

            <ng-container *ngIf="icon">
                <img src="assets/icon/button-icon/{{getIconNameStaticDynamic()[0]}}" height="24"
                    [class.d-inline-block]="!actived && !isMouseOver" />
                <img src="assets/icon/button-icon/{{getIconNameStaticDynamic()[1]}}" height="24"
                    [class.d-inline-block]="!actived && isMouseOver" />
                <img src="assets/icon/button-icon/{{getIconNameStaticDynamic()[2]}}" height="24"
                    [class.d-inline-block]="actived && !isMouseOver" *ngIf="getIconNameStaticDynamic()[2]" />
                <img src="assets/icon/button-icon/{{getIconNameStaticDynamic()[3]}}" height="24"
                    [class.d-inline-block]="actived && isMouseOver" *ngIf="getIconNameStaticDynamic()[3]" />
            </ng-container>

            <ng-content select="i"></ng-content>
            <span *ngIf="text">{{text}}</span>
        </button>
        <button class="reset-btn" *ngIf="double && !navLink" (click)="onPressSecondBtn()"><i
                class="far fa-times"></i></button>
    </div>
</div>