import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// google maps
import { GoogleMapsModule } from '@angular/google-maps';
import { RouterModule } from '@angular/router';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
// ng-bootstrap
import { NgbDropdownModule, NgbModalModule, NgbToastModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
// dynamically inline svg
import { InlineSVGModule } from 'ng-inline-svg-2';
import { DndModule } from 'ngx-drag-drop';
import { ImageCropperComponent } from 'ngx-image-cropper';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { HasRoleDirective } from './directives/has-role.directive';
import { GoogleMapsComponent } from './google-maps/google-maps.component';
import { ModalManageGalleryComponent } from './modal-manage-gallery/modal-manage-gallery.component';
import { PlanetBreadcrumbsComponent } from './planet-breadcrumbs/planet-breadcrumbs.component';
import { PlanetButtonComponent } from './planet-button/planet-button.component';
import { DndDirective } from './planet-dnd-upload/dnd.directive';
import { PlanetDndUploadComponent } from './planet-dnd-upload/planet-dnd-upload.component';
import { PlanetDropdownButtonComponent } from './planet-dropdown-button/planet-dropdown-button.component';
import { PlanetHeadlineComponent } from './planet-headline/planet-headline.component';
import { PlanetImageCropperComponent } from './planet-image-cropper/planet-image-cropper.component';
import { PlanetInputComponent } from './planet-input/planet-input.component';
import { PlanetLoaderComponent } from './planet-loader/planet-loader.component';
import { PlanetMultiselectComponent } from './planet-multiselect/planet-multiselect.component';
import { PlanetRatingComponent } from './planet-rating/planet-rating.component';
import { PlanetRichTextEditorComponent } from './planet-rich-text-editor/planet-rich-text-editor.component';
import { PlanetSearchboxComponent } from './planet-searchbox/planet-searchbox.component';
import { PlanetSearchfieldComponent } from './planet-searchfield/planet-searchfield.component';
import { PlanetTableHeaderTdComponent } from './planet-table/planet-table-header-td/planet-table-header-td.component';
import { PlanetTableTdComponent } from './planet-table/planet-table-td/planet-table-td.component';
import { PlanetTableTrComponent } from './planet-table/planet-table-tr/planet-table-tr.component';
import { PlanetTableComponent } from './planet-table/planet-table.component';
import { PlanetTabsComponent } from './planet-tabs/planet-tabs.component';
import { PlanetTagComponent } from './planet-tag/planet-tag.component';
import { PlanetTextAreaComponent } from './planet-text-area/planet-text-area.component';
import { PlanetToastComponent } from './planet-toast/planet-toast.component';
import { PlanetToggleSwitchComponent } from './planet-toggle-switch/planet-toggle-switch.component';
import { PlanetTreeListItemComponent } from './planet-tree-list/planet-tree-list-item/planet-tree-list-item.component';
import { PlanetTreeListComponent } from './planet-tree-list/planet-tree-list.component';
import { PlanetTileComponent } from './planet-tile/planet-tile.component';

@NgModule({
  declarations: [
    HasRoleDirective,
    PlanetLoaderComponent,
    PlanetButtonComponent,
    PlanetSearchfieldComponent,
    PlanetSearchboxComponent,
    PlanetTabsComponent,
    PlanetTableComponent,
    PlanetTableHeaderTdComponent,
    PlanetTableTrComponent,
    PlanetTableTdComponent,
    PlanetTagComponent,
    PlanetMultiselectComponent,
    PlanetTreeListComponent,
    PlanetTreeListItemComponent,
    PlanetToastComponent,
    PlanetDropdownButtonComponent,
    PlanetDndUploadComponent,
    DndDirective,
    PlanetInputComponent,
    PlanetTextAreaComponent,
    PlanetBreadcrumbsComponent,
    GoogleMapsComponent,
    PlanetRatingComponent,
    PlanetToggleSwitchComponent,
    ModalManageGalleryComponent,
    PlanetRichTextEditorComponent,
    PlanetHeadlineComponent,
    PlanetImageCropperComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,

    // ngx-mask
    NgxMaskDirective,
    NgxMaskPipe,

    InlineSVGModule,
    VirtualScrollerModule,
    DndModule,

    // ng-bootstrap
    NgbTooltipModule,
    NgbToastModule,
    NgbDropdownModule,
    NgbModalModule,

    // google maps
    GoogleMapsModule,

    NgSelectModule,
    FormsModule,
    CKEditorModule,
    ImageCropperComponent,    
    PlanetTileComponent
  ],
  exports: [
    TranslateModule,
    HasRoleDirective,
    InlineSVGModule,
    VirtualScrollerModule,
    DndModule,

    // ng-bootstrap
    NgbTooltipModule,
    NgbToastModule,
    NgbDropdownModule,
    NgbModalModule,

    PlanetLoaderComponent,
    PlanetButtonComponent,
    PlanetSearchfieldComponent,
    PlanetSearchboxComponent,
    PlanetTabsComponent,
    PlanetTableComponent,
    PlanetTableHeaderTdComponent,
    PlanetTableTrComponent,
    PlanetTableTdComponent,
    PlanetTagComponent,
    PlanetRatingComponent,
    PlanetToggleSwitchComponent,

    NgSelectModule,
    FormsModule,
    PlanetMultiselectComponent,

    PlanetTreeListComponent,
    PlanetTreeListItemComponent,
    PlanetToastComponent,
    PlanetDropdownButtonComponent,
    PlanetDndUploadComponent,
    PlanetInputComponent,
    PlanetTextAreaComponent,
    PlanetBreadcrumbsComponent,
    GoogleMapsComponent,
    ModalManageGalleryComponent,
    PlanetRichTextEditorComponent,
    PlanetHeadlineComponent,
    ImageCropperComponent,
    PlanetImageCropperComponent,
    PlanetTileComponent
  ],
  providers: [provideNgxMask()]
})
export class SharedModule { }
