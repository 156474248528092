import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-planet-button',
  templateUrl: './planet-button.component.html',
  styleUrls: ['./planet-button.component.scss']
})
export class PlanetButtonComponent implements OnInit {

  @Input()
  btnClass: 'btn-xs' | string = '';

  @Input()
  text: string = null;

  @Input()
  icon: string = null;

  @Input()
  actived = false;

  @Input()
  double = false; // double button

  @Input()
  hoverIconBob = false;

  @Input()
  navLink: string;

  @Output()
  pressedFirst = new EventEmitter<boolean>();

  @Output()
  pressedSecond = new EventEmitter<boolean>();

  isMouseOver = false;

  constructor() { }

  ngOnInit() {
  }

  onMouseOver() {
    this.isMouseOver = true;
  }

  onMouseLeave() {
    this.isMouseOver = false;
  }

  getIconNameStaticDynamic(): string[] {
    switch (this.icon) {
      case 'add': return ['add_static.png', 'add_dynamic.png'];
      case 'edit': return ['edit_static.png', 'edit_dynamic.png'];
      case 'download': return ['download_static.png', 'download_dynamic.png'];
      case 'print': return ['print_static.png', 'print_dynamic.png'];
      case 'filter': return ['filter_static.png', 'filter_dynamic.png', 'filter_selected_static.png', 'filter_selected_dynamic.png'];
      default: return [];
    }
  }

  onPressFirstBtn() {
    this.pressedFirst.emit(true);
  }

  onPressSecondBtn() {
    this.pressedSecond.emit(true);
  }

  openUrl(url: string) {
    window.open(url, '_blank'); // new browser tab
  }

}
