export enum HasuraRole {
  DASHBOARD_VIEWER_FULL = 'DASHBOARD_VIEWER_FULL',
  CATALOG_ADMIN = 'CATALOG_ADMIN',
  CATALOG_EDITOR = 'CATALOG_EDITOR',
  CATALOG_VIEWER_FULL = 'CATALOG_VIEWER_FULL',
  CATALOG_VIEWER_PART = 'CATALOG_VIEWER_PART',
  CONFIGURATOR_ADMIN = 'CONFIGURATOR_ADMIN',
  CONFIGURATOR_EDITOR = 'CONFIGURATOR_EDITOR',
  CONFIGURATOR_ADMIN_ARUP = 'CONFIGURATOR_ADMIN_ARUP',
  MAP_VIEWER_FULL = 'MAP_VIEWER_FULL',
  MAP_VIEWER_PART = 'MAP_VIEWER_PART',
  INDEX_ADMIN = 'INDEX_ADMIN',
  INDEX_ADMIN_ARUP = 'INDEX_ADMIN_ARUP',
  ADDRESS_BOOK_ADMIN = 'ADDRESS_BOOK_ADMIN',
  ADDRESS_BOOK_VIEWER_FULL = 'ADDRESS_BOOK_VIEWER_FULL',
  ALIAS_ADMIN = 'ALIAS_ADMIN',
  ALIAS_EDITOR = 'ALIAS_EDITOR',
}
