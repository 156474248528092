import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MsalBroadcastService, MsalGuard, MsalService } from '@azure/msal-angular';
// ngx-translate
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ApolloModule } from 'apollo-angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
// import { SignInComponent } from './pages/sign-in/sign-in.component';
import { AuthErrorOverlayComponent } from './auth/auth-error-overlay/auth-error-overlay.component';
import { MSALGuardConfigFactory, MSALInstanceFactory } from './msal.config';
import { PlanetLoaderService } from './shared/planet-loader/planet-loader.service';
import { SharedModule } from './shared/shared.module';
import { OrderEditService } from './signed/pages/configurator-v2/configurator-edit/order-edit/order-edit.service';
import { FeatureFlagDirective } from './feature-flag/feature-flag.directive';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    AuthErrorOverlayComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ApolloModule,
    SharedModule,
    FeatureFlagDirective
  ],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalGuard,
    MsalService,
    MsalBroadcastService,
    PlanetLoaderService,
    OrderEditService,
  ]
})
export class AppModule { }
