import { customAlphabet } from 'nanoid';

const alphabet = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
export const nanoid = customAlphabet(alphabet, 30);

export function currentPath() {
    return location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '');
}

// group by
export const groupBy = (xs: any[], key: string) => {
    return xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

// sort by
export const sortByNumber = (arr: any[], prop: string, desc?: boolean) => {
    arr.sort((a: any, b: any) => desc ? b[prop] - a[prop] : a[prop] - b[prop]);
};

export const sortBy = (data: any[], sorting1: string, sorting2?: string, desc?: boolean) => {
    data.sort((a: any, b: any) => {
        if (a[sorting1] && b[sorting1]) {
            const firstCompare = typeof a[sorting1] === 'string' ? a[sorting1].localeCompare(b[sorting1])
                : a[sorting1] - b[sorting1];
            if (firstCompare === 0 && sorting2) {
                return compareWithNulls(a, b, sorting2);
            } else {
                return desc ? firstCompare * -1 : firstCompare;
            }

        } else if (a[sorting1] && !b[sorting1]) {
            return desc ? 1 : -1;
        } else if (!a[sorting1] && b[sorting1]) {
            return desc ? -1 : 1;
        } else {
            if (sorting2) {
                return compareWithNulls(a, b, sorting2);
            } else {
                return 0;
            }
        }
    });
};
const compareWithNulls = (a: any, b: any, prop: string) => {
    if (a[prop] && b[prop]) {
        return a[prop] === 'string' ? a[prop].localeCompare(b[prop]) : a[prop] - b[prop];
    } else if (a[prop] && !b[prop]) {
        return -1;
    } else if (!a[prop] && b[prop]) {
        return 1;
    } else {
        return 0;
    }
};

export const compareIgnoreCase = (a: string, b: string) => {
    return a?.toLowerCase()?.localeCompare(b?.toLowerCase());
};

// load image
export const loadImage = (src: string) => new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve(img);
    img.onerror = error => reject(error);
    img.src = src;
});

// input file conversion
export const toBase64 = (file: any) => new Promise<string | ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});
export const toText = (file: any) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
});

export const convertBase64ToFile = (image: string) => {
    const byteString = atob(image.split(',')[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: 'image/jpeg' });
};
export const convertSvgToFile = (svg: string) => {
    return new Blob([svg], { type: 'image/svg+xml' });
};

// breadcrumbs
export const getBreadcrumbs = (detailPage: 'SOL' | 'CAT' | 'P_S', newCrumb: string, newCrumbId: number, detailType?: 1 | 2) => {
    let breadcrumbs = JSON.parse(sessionStorage.getItem('cat_breadcrumbs'));

    switch (detailPage) {
        case 'SOL':
            if (breadcrumbs) {
                breadcrumbs = breadcrumbs.filter((e: any) => e.type === 'LIST');
                if (!breadcrumbs.length) {
                    breadcrumbs.push({
                        text: 'catalog.detail.navToSolutions', routerLink: '/s/m/catalog/tab/needs'
                    });
                }
            } else {
                breadcrumbs = [{ text: 'catalog.detail.navToSolutions', routerLink: '/s/m/catalog/tab/needs' }];
            }
            breadcrumbs.push({ text: newCrumb, routerLink: '/s/m/catalog/need/' + newCrumbId, type: 'SOL' });
            break;
        case 'CAT':
            if (breadcrumbs) {
                breadcrumbs = breadcrumbs.filter((e: any) => e.type === 'LIST' || e.type === 'SOL');
                if (!breadcrumbs.length) {
                    breadcrumbs.push({
                        text: 'catalog.detail.navToCategories', routerLink: '/s/m/catalog/tab/solutions'
                    });
                }
            } else {
                breadcrumbs = [{ text: 'catalog.detail.navToCategories', routerLink: '/s/m/catalog/tab/solutions' }];
            }
            breadcrumbs.push({
                text: newCrumb, routerLink: '/s/m/catalog/' + (detailType === 1 ?
                    'solution-product' : 'solution-service') + '/' + newCrumbId, type: 'CAT'
            });
            break;
        case 'P_S':
            if (breadcrumbs) {
                breadcrumbs = breadcrumbs.filter((e: any) => e.type !== 'P_S');
                if (!breadcrumbs.length) {
                    breadcrumbs.push({
                        text: 'catalog.detail.navToProdServ', routerLink: '/s/m/catalog/tab/products-services'
                    });
                }
            } else {
                breadcrumbs = [{
                    text: 'catalog.detail.navToProdServ', routerLink: '/s/m/catalog/tab/products-services'
                }];
            }
            breadcrumbs.push({
                text: newCrumb, routerLink: '/s/m/catalog/' + (detailType === 1 ? 'product' : 'service') + '/' + newCrumbId, type: 'P_S'
            });
            break;
    }

    sessionStorage.setItem('cat_breadcrumbs', JSON.stringify(breadcrumbs));
    return breadcrumbs;
};

// regex
export const isIntegerGtZero = (input: any) => {    // int greater than 0
    return /^[1-9]\d*$/.test(input);
};
export const isPositiveNoZero = (input: any) => {   // positive (optionally decimal) excluding zero
    return /^[+]?([1-9][0-9]*(?:[\.][0-9]*)?|0*\.0*[1-9][0-9]*)$/.test(input);
};

export const isEmail = (email: string): boolean => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
};


export const randomString = (length: number): string => {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export const isValidHttpsUrl = (input: string) => {
    let url: URL;
    try {
        url = new URL(input);
    } catch (error) {
        return false;
    }
    return url.protocol === 'https:';
};

export const isValidUrl = (input: string) => {
    try {
        new URL(input);
        return true;
    } catch (err) {
        return false;
    }
}

export const objectContainOnlyThisKeys = (obj: any, keys: string[]) => {
    return Object.keys(obj).every(key => keys.includes(key)) && keys.length === Object.keys(obj).length;
}