import { Injectable } from '@angular/core';

@Injectable()
export class OrderEditService {

  saveEnabled = false;
  selectedScenarioId: number;
  selectedScenarioIdFromTree: number;

  constructor() { }

}
