import { Injectable } from '@angular/core';

@Injectable()
export class PlanetLoaderService {
  // FIX: Detroy this service and use a SIMPLE "isLoading" input on app-planet-loader component
  loading = false;

  constructor() {
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }

}
