export enum UserGroup {
    KIT_DASHBOARD_VIEWER_FULL = 'KIT_DASHBOARD_VIEWER_FULL',
    KIT_DASHBOARD_VIEWER_FULL_O365 = 'c6391c8d-25de-4cc7-891b-457394dc257c',
    KIT_CATALOG_ADMIN = 'KIT_CATALOG_ADMIN',
    KIT_CATALOG_EDITOR = 'KIT_CATALOG_EDITOR',
    KIT_CATALOG_CONTRIBUTOR_O365 = '45a999de-c09f-402b-8bfa-704e53a4ec9a',
    KIT_CATALOG_VIEWER_FULL = 'KIT_CATALOG_VIEWER_FULL',
    KIT_CATALOG_VIEWER_FULL_O365 = '8b48a3f3-0a9a-4a8f-a603-3a168d6a9e69',
    KIT_CATALOG_VIEWER_PART = 'KIT_CATALOG_VIEWER_PART',
    KIT_CATALOG_VIEWER_PART_O365 = '67ff7963-7635-426d-b205-b4d397707a5e',
    KIT_CONFIGURATOR_ADMIN = 'KIT_CONFIGURATOR_ADMIN',
    KIT_CONFIGURATOR_EDITOR = 'KIT_CONFIGURATOR_EDITOR',
    KIT_CONFIGURATOR_ADMIN_ARUP = 'KIT_CONFIGURATOR_ADMIN_ARUP',
    KIT_CONFIGURATOR_ADMIN_FOSTER_O365 = 'b1352103-11ce-4ff6-be99-7b8ef90abc72',
    KIT_SMART_MAP_VIEWER_FULL = 'KIT_SMART_MAP_VIEWER_FULL',
    KIT_MAP_VIEWER_FULL_O365 = '046941d7-3d2b-44b6-a307-43102525a8bc',
    KIT_SMART_MAP_VIEWER_EXT = 'KIT_SMART_MAP_VIEWER_EXT',
    KIT_MAP_VIEWER_PART_O365 = 'a17ac026-a0a6-4bb8-ab02-42ca54590b47',
    KIT_INDEX_ADMIN = 'KIT_INDEX_ADMIN',
    KIT_INDEX_ADMIN_ARUP = 'KIT_INDEX_ADMIN_ARUP',
    KIT_ADDRESS_BOOK_ADMIN = 'KIT_ADDRESS_BOOK_ADMIN',
    KIT_ADDRESS_BOOK_VIEWER_FULL = 'KIT_ADDRESS_BOOK_VIEWER_FULL'
}
