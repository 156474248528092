import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';

const routes: Routes = [
  {
    path: '',
    redirectTo: 's',
    pathMatch: 'full'
  },
  {
    path: 's', // s for signed
    loadChildren: () => import('./signed/signed.module').then(m => m.SignedModule),
    canActivate: [MsalGuard], // TODO: use a custom guard with canLoad
  },
  {
    path: '**',
    redirectTo: 's'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes,
      {
        enableTracing: (environment.enableTracing || false) && !environment.production,
      })
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule { }
