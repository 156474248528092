import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationError, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ReleaseManagerService {
  private readonly checkVersionTimeout = 5000;
  private currentVersion: null | string = null;
  public readonly onNewVersion = new Subject<boolean>();
  inited = false;
  constructor(
    private router: Router,
    private http: HttpClient
  ) { }

  init() {
    if (!this.inited) {
      this.inited = true;
      this.handleChunkLoadError();
      this.periodicCheckNewVersion();
    }
  }

  private handleChunkLoadError() {
    this.router.events
      .pipe(filter(event => event instanceof NavigationError))
      .pipe(filter((event: NavigationError) => event.error.name === 'ChunkLoadError'))
      .subscribe((event: NavigationError) => {
        // we force call the web server to get the new index.html
        // directing the user to the desired page
        window.location.href = window.location.origin + event.url;
      });
  }

  private periodicCheckNewVersion() {
    setInterval(() => {
      this.http.get('/assets/release_rand.txt', {
        headers: {
          'Cache-Control': 'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
          'Pragma': 'no-cache',
          'Expires': '0'
        },
        responseType: 'text',
      }).toPromise().then((version: string) => {
        if (this.currentVersion === null) {
          this.currentVersion = version;
        } else if (this.currentVersion !== version) {
          this.currentVersion = version;
          this.onNewVersion.next(true);
          this.onNewVersion.complete();
        }
      }).catch(err => { console.error(err) });
    }, this.checkVersionTimeout);
  }

}
