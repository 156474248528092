import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { currentPath } from '@script/utilities';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as LogRocket from 'logrocket';

if (environment.production) {
  enableProdMode();

  /*
  if (currentPath().indexOf('kit.planetidea.com') === -1) {
    window.location.href = 'kit.planetidea.com';
  }*/

  if (environment.logRocketAppId) {
    console.log = LogRocket.log;
  } else {
    // eslint-disable-next-line
    console.log = (message?: any, ...optionalParams: any[]) => { };
  }
  // eslint-disable-next-line no-console
  // console.info = (message?: any, ...optionalParams: any[]) => { };
}

if (environment.logRocketAppId) {
  LogRocket.init(environment.logRocketAppId);
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
