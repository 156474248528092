import { Component } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-auth-error-overlay',
  templateUrl: './auth-error-overlay.component.html',
  styleUrl: './auth-error-overlay.component.scss'
})
export class AuthErrorOverlayComponent {

  constructor(
  ) { }

  get isProdEnv( ) {
    return environment.environmentName === 'prod';
  }

  onRetry() {
    window.location.href = '/';
  }

}
